import React from "react";
import { Row, Col, Container } from "react-bootstrap";

export default function WhyChoose() {
    return (
        <div className="choose-bg py-5" style={{ backgroundColor: '#d5a092' }}>
            <div className="choose-content-wrapper" style={{ padding: '3rem', backgroundColor: '#d5a092' }}>
            <Container className="choose-content bg-white p-4 rounded shadow">
                <Row className="align-items-center">
                    <Col md={6}>
                        <img
                            src="images/choose.webp"
                            alt="Cake and coffee"
                            className="img-fluid rounded"
                            loading="lazy"
                        />
                    </Col>
                    <Col md={6}>
                        <h2 className="mb-4" style={{ fontFamily: "'Dancing Script', cursive", color: "#333" }}>Why Choose us?</h2>
                        <ul>
                        <li><strong>Expert Instructors:</strong> Learn from experienced bakers and pastry chefs who are passionate about teaching.</li>
                        <li><strong>Hands-on Training: </strong>Gain practical experience through hands-on classes and workshops.</li>
                        <li><strong>Comprehensive Curriculum:</strong> Our courses cover a wide range of topics, from foundational techniques to advanced pastry arts.</li>
                        <li><strong>State-of-the-Art Facilities:</strong> Train in a fully equipped, modern kitchen environment.</li>
                        <li><strong>Inspiration and Creativity:</strong> We encourage creativity and innovation, helping students to develop their unique style.</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            </div>
        </div>
    );
}
