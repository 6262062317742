import React, { useState, useEffect } from "react";

export default function Maincontent() {
    const sliderItems = [
        { image: 'images/banners.jpg' },
        
    ];

    // State to track the current active slide
    const [currentIndex, setCurrentIndex] = useState(0);

    // Effect to handle automatic slide changes
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % sliderItems.length);
        }, 5000); // Change slide every 5 seconds

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, [sliderItems.length]);
    return (
        <>
            <div className="main-content" id="home">

                <div className="background-slider">
                    {sliderItems.map((item, index) => (
                        <div
                            key={index}
                            className={`slider-item ${index === currentIndex ? 'active' : ''}`}
                        >
                            <div
                                className="slider-image"
                                style={{ backgroundImage: `url(${item.image})` }}
                            >
                                <div className="centered-text">
                                    <h1>Bake Your Way to Success!</h1>
                                    <p>From Beginner to Expert – We Have a Course for Everyone.</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>


                
            </div>

        </>
    )
}