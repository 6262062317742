import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function Contactus() {
    return (
        <>
            <div className="aboutus_bg">
                <div className="aboutus-img-content">
                    <h1>Contact Us</h1>
                </div>
            </div>
            <div className="contact-tophead">
                <div className="contact-subcontent" style={{ marginTop: '6%' }}>
                    <Container>
                        <Row>
                            <Col md={3}>
                                <div className="contact-item">
                                <i class="fa-solid fa-envelope fa-2xl" style={{color:"#d5a092",marginTop:'10%',marginBottom:'16%'}}></i>
                                    <h3>Email</h3>
                                    <p>Email address:<br /> info@finesseacademyarts.com</p>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="contact-item">
                                <i className="fa fa-clock fa-2xl" style={{color:"#d5a092",marginTop:'10%',marginBottom:'16%'}}></i>
                                    <h3>Hours</h3>
                                    <p>Mon-Sat: 9.00 am-5.00 pm<br /> Weekends - closed</p>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="contact-item">
                                <i className="fa fa-location-dot fa-2xl" style={{color:"#d5a092",marginTop:'10%',marginBottom:'16%'}}></i>
                                    <h3>Our Location</h3>
                                    <p>No 46/1, 1st Floor, K.B. Dasen Road<br /> Teynampat, Chennai - 18.</p>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="contact-item">
                                <i className="fa fa-phone fa-2xl" style={{color:"#d5a092",marginTop:'10%',marginBottom:'16%'}}></i>
                                    <h3>Call us</h3>
                                    <p>Phone number:<br />+91 9445744663</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="form-content" style={{ marginTop: '5%' }}>
                    <Container>
                        <Row>
                            <Col md={6}>
                                <div className="contact-form">
                                    <h2>Write us a Message</h2>
                                    <form>
                                        <div className="form-group">
                                            <input type="text" placeholder="Name*" required />
                                            <input type="email" placeholder="Email Address*" required />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" placeholder="Subject" />
                                        </div>
                                        <div className="form-group">
                                            <textarea placeholder="Message*" required></textarea>
                                        </div>
                                        <button type="submit" className="btn-submit">SEND MESSAGE</button>
                                    </form>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="map-section">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.003221613748!2d80.24681207489415!3d13.035466587285814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267000f1a6d27%3A0x92f175f77c7f98f3!2sFinesse%20Academy%20for%20Bakery%20and%20Pastry%20Arts!5e0!3m2!1sen!2sin!4v1725254436469!5m2!1sen!2sin"
                                        width="100%"
                                        height="400"
                                        frameBorder="0"
                                        allowfullscreen="" 
                                        loading="lazy" 
                                        referrerpolicy="no-referrer-when-downgrade"
                                        title="Location Map">
                                    </iframe>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
} 