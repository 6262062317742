// eslint-disable-next-line jsx-a11y/anchor-is-valid
import React from 'react';
import { Card, Button, Accordion, Row, Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function Professional() {
    const navigate = useNavigate();

    const navigateToCourses = () =>{
        navigate('/contactus');
    }
    return (
        <>
            <div className="aboutus_bg">
                <div className="aboutus-img-content">
                    <h1>Professional Bakery Courses</h1>
                </div>
            </div>
            <div className='diplomaPge'>
                <section>
                    <div className="header-section" style={{ fontFamily: 'DancingScript,cursive' }}>
                        <h1 style={{ fontSize: '28px' }}>Professional Bakery and Confectionery Course</h1>
                    </div>
                </section>

                <Row style={{ margin: '0', padding: '0' }}>
                    <Col md={8} style={{ paddingLeft: "5%", lineHeight: '2.5' }}>

                        {/* Header Section */}
                        <section className="about-course" >
                            <h2>About the Course</h2>
                            <p style={{ textAlign: 'justify' }}>Our Professional Bakery and Confectionery 
                                Course is designed for individuals who are passionate 
                                about mastering the art of baking and confectionery. 
                                Whether you’re an aspiring baker or a seasoned 
                                professional looking to enhance your skills, this 
                                comprehensive program offers a deep dive into the world 
                                of professional baking, pastries, and confectionery.</p>
                        </section>

                        <section className="learn-section" style={{ fontFamily: 'Roboto' }}>
                            <h2>What Will You Learn?</h2>
                            <ul>
                                <li>From basic bread-making to intricate cake designs, learn the foundational skills needed to create bakery-quality products.</li>
                                <li>Explore the world of fine pastries, tarts, and layered desserts, perfecting your craft with expert guidance.</li>
                                <li>Discover the techniques behind creating a variety of confectioneries, including chocolates, candies, and sugar art.</li>
                                <li>Gain insight into maintaining hygiene, safety standards, and efficient kitchen management that align with industry practices.</li>
                                <li>Learn the art of presenting your baked goods and confections with a focus on creativity and elegance.</li>
                               
                            </ul>
                        </section>

                        <section className="about-course" >
                            <h2>Certification:</h2>
                            <p style={{ textAlign: 'justify' }}>Our Diploma program is affiliated with the Council for Vocational Education and Development (CVESD), a government-approved organization. Upon successful completion, students will receive:</p>
                            <ul>
                                <li>Finesse Academy for Bakery and Pastry Arts.</li>
                                <li> CVESD (Council for Vocational Education and Skill Development), Government-approved.</li>
                            </ul>
                        </section>

                        
                        {/* What Will You Learn Section */}
                        {/* <section className="learn-section">
                            <h2>Certification</h2>
                            <ul>
                                <li>This dual certification ensures our students meet industry standards and are well-prepared for a successful career in bakery and pastry arts.</li>

                                {/* <li> Learn to recite the memorized verses with precise pronunciation following Tajweed rules.</li>
                                <li>Progress at your own pace with a tailored memorization plan designed to suit your learning speed.</li>
                                <li>Develop a deeper spiritual connection with the Quran, enhancing your understanding and appreciation of its teachings.</li> */}
                            {/* </ul>
                        </section> */}



                        {/* Course Curriculum Section */}
                        {/* <section className="curriculum-section">
                            <h2>Course Curriculum</h2>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Introduction to Hifz</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Overview of the importance of memorizing the Quran. Setting intentions and mental preparation for the Hifz journey.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Personalized Memorization Plan</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Assessment of the student's ability and learning speed. Creation of a tailored memorization schedule (daily/weekly goals).</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Memorization Sessions</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Step-by-step memorization of selected surahs, starting with shorter surahs. Focus on repetition and retention techniques to aid memory.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Tajweed and Pronunciation</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Ongoing instruction in Tajweed to ensure correct recitation. Application of Tajweed rules to the memorized verses.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Daily and Weekly Revision (Muraja'ah)</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Regular review sessions of previously memorized portions. Structured revision system to maintain long-term retention.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Progress Assessments</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Monthly assessments to evaluate memorization progress. Feedback and adjustments to the memorization plan as needed.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>Completion and Final Review</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Final review of the entire memorized portion before completion. Certificate awarded upon successful completion of the course.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </section> */}
                    </Col>
                    <Col md={4}>
                        {/* Sidebar Section */}
                        <div className="course-sidebar">
                            <Card className="sidebar-card">
                                <Card.Body>
                                    {/* YouTube Video */}
                                    <div className="video-container">
                                        <img src='images/sweet.webp' style={{ width: '100%' }} />
                                    </div>

                                    {/* Pricing */}
                                    <p className="course-price">₹ 30,000<br/> 
                                    <span className='course-amt'>(Inclusive of GST)</span></p>

                                    <button onClick={navigateToCourses} className="add-to-cart">Enrol Now</button>
                                    {/* <Button variant="outline-primary" className="buy-course" block>Buy Course</Button> */}

                                    {/* <hr className="course-divider" /> */}
                                    &nbsp;
                                    {/* Course Details */}
                                    <ul className="course-includes">
                                        {/* <li><strong>Lectures:</strong> 12</li> */}
                                        <li><strong>Duration:</strong> 7 weeks</li>
                                        <li><strong>Class Schedule: </strong> 3 days a week</li>
                                        <li><strong>Timing: </strong> 10.00 am-5.00 pm</li>
                                        {/* <li><strong>⁠Fees: </strong> Rs. 125,000 (inclusive of GST),<br /> payable in 2 installments</li> */}
                                        {/* <li><strong>Deadline:</strong> November 29, 2024</li> */}
                                        <li><strong>Certificate:</strong> Yes</li>
                                    </ul>
                                    {/* <Button variant="outline-primary" className="share-course">Share this course</Button> */}
                                </Card.Body>

                            </Card>
                        </div>
                    </Col>
                </Row>
                {/* About Course Section */}
            </div>

        </>
    )
}