import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

export default function Onecourse(){
    const classes = [
        {
            image: "images/cookie.webp",
            date: "Timings:10 AM to 5 PM 1 Days",
            title: "Cookies",
            description:"Chocolate chip cookies, Danish butter cookies, thumbprint cookies, piping cookies, brownie, oats and raisin cookies, salt cookies, biscotti, lime shortbread.",

        },
        {
            image: "images/travel.webp",
            date: "Timings:10 AM to 5 PM 1 Days",
            title: "Travel cakes",
            description:"Classic marble cake, dark chocolate loaf, lemon cake, carrot cake, pineapple upside down.",
        },
        {
            image: "images/muffin.webp",
            date: "Timings:10 AM to 5 PM 1 Day ",
            title: "Muffins",
            description:"Chocolate chip muffin, vanilla berry, oats and cherry, coconut, apple and cinnamon, savoury muffin.",
        },
        {
            image: "images/cup.webp",
            date: "Timings:10 AM to 5 PM 1 Day ",
            title: "Cupcakes",
            description:"Chocolate cupcake, red velvet cupcake, vanilla coffee, carrot and walnut, banana caramel cupcake.",
        },
        {
            image: "images/sweet.webp",
            date: "Timings:10 AM to 5 PM 1 Day ",
            title: "Sweet tarts",
            description:"Chocolate custard tart, lemon meringue tart, fruit tart, bakewell tart, apple pie.",
        },
        {
            image: "images/savour.webp",
            date: "Timings:10 AM to 5 PM 1 Day ",
            title: "Savoury bakes",
            description:"Korean cheese bun, chicken sausage quiche, creamy mushroom tart, stromboli, pizza.",
        },
        {
            image: "images/cheese.webp",
            date: "Timings:10 AM to 5 PM 1 Day ",
            title: "All about cheesecakes",
            description:"New York cheese, chocolate basque cheesecake, Japanese cheesecake, no bake cold cheesecake.",
        },
        {
            image: "images/egg.webp",
            date: "Timings:10 AM to 5 PM 1 Day ",
            title: "Eggless bakes",
            description:"Peanut butter cookies, coconut lime tart, dark chocolate cupcake, vanilla pound cake, blondie.",
        },
        {
            image: "images/choux.webp",
            date: "Timings:10 AM to 5 PM 1 Day ",
            title: "Choux pastry",
            description:"Chocolate eclair, lemon meringue eclair, red berry profiteroles, Paris Brest, hazelnut profiteroles, caramel religieuse.",
        },
        {
            image: "images/merin.webp",
            date: "Timings:10 AM to 5 PM 1 Day ",
            title: "Meringues and macarons",
            description:"Pavlova, vacherin, Italian meringue macaron, French meringue macarons.",
        },

    ];
    return(
        <>
       
            <div className="class-head" id="course">
               
                <div className="pop-class">
                    <h3 style={{paddingBottom:'3%'}}>1 Day Programs</h3>
                    <Container>
                        <Row >
                            {classes.map((cls, index) => (

                                <Col md={4} key={index} style={{ marginBottom: '5%' }}>
                                    <Card className="cookie" style={{ border: 'none' }}>
                                        <Card.Body>
                                            <div className="image">
                                                <Card.Img src={cls.image} className="fixed-height-img" loading="lazy"/>
                                                <div className="cls-text">
                                                    <Card.Text>
                                                        <p className="cls-date">
                                                            <i className="fa fa-clock" style={{ color: "#e65c8c" }}></i>&nbsp;
                                                            {cls.date}
                                                        </p>
                                                    </Card.Text>
                                                    <Card.Title>
                                                        <h5>{cls.title}</h5>
                                                    </Card.Title>
                                                    <Card.Text>
                                                            <p className="cls-para">{cls.description}</p>
                                                        </Card.Text>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                            }
                        </Row>
                    </Container>
                </div>
                
            </div>
        </>
    )
}