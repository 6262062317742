import './App.css';
import './Style.css';
import NavbarComponent from './Component/Navbar';
import { Route, Routes } from 'react-router-dom';
import Home from './Component/Home';
import Aboutus from './Component/Aboutus';
import Courses from './Component/Courses';
import Contactus from './Component/Contactus';
import ScrollToTop from './Component/ScrollToTop';
import Footer from './Component/Footer';
import Diploma from './Component/Diploma';
import Professional from './Component/Professional';
import Novschedule from './Component/Novschedule';
import Cheesecake from './Component/Cheesecake';
import Egglessbake from './Component/Egglessbake';
import Classicdessert from './Component/Classicdessert';
import Sweettarts from './Component/Sweettarts';
import Enrichbread from './Component/Enrichbread';
import Puffpastry from './Component/Puffpastry';
import DecSchedule from './Component/DecSchedule';
import Festivefavorites from './Component/Festivefavorites';
import Basicofbread from './Component/Basicofbread';
import Cookiesmacron from './Component/Cookiesmacron';
import Muffins from './Component/Muffins';
import Travelcake from './Component/Travelcake';
import Cupcakes from './Component/Cupcakes';
import Tarts from './Component/Tarts';
import Custard from './Component/Custard';
import Puffpastrian from './Component/Puffpastrian';
import Viennoiseries from './Component/Viennoiseries';

function App() {
  return (
    <>
    <ScrollToTop/>
    <NavbarComponent/>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/aboutus' element={<Aboutus/>}/>
      <Route path='/courses' element={<Courses/>}/>
      <Route path='/contactus' element={<Contactus/>}/>
      <Route path='/diploma' element={<Diploma/>}/>
      <Route path='/professional-bakery' element={<Professional/>}/>
      {/* <Route path='/novemberschedule' element={<Novschedule/>}/>
      <Route path='/cheesecake' element={<Cheesecake/>}/>
      <Route path='/egglessbake' element={<Egglessbake/>}/>
      <Route path='/classicdessert' element={<Classicdessert/>}/>
      <Route path='/sweettarts' element={<Sweettarts/>}/>
      <Route path='/viennoiseries and enriched breads' element={<Enrichbread/>}/>
      <Route path='/puffpastry' element={<Puffpastry/>}/> */}
      <Route path='/decemberschedule' element={<DecSchedule/>}/>
      <Route path='/festivefavorites' element={<Festivefavorites/>}/>
      <Route path='/basicsofbread' element={<Basicofbread/>}/>
      <Route path='/cookies&macaron' element={<Cookiesmacron/>}/>
      <Route path='/muffins' element={<Muffins/>}/>
      <Route path='/travelcakes' element={<Travelcake/>}/>
      <Route path='/cupcakes' element={<Cupcakes/>}/>
      <Route path='/tarts' element={<Tarts/>}/>
      <Route path='/custards and classic desserts' element={<Custard/>}/>
      <Route path='/puffpastries' element={<Puffpastrian/>}/>
      <Route path='/viennoiseries' element={<Viennoiseries/>}/>
     
    </Routes>
    <Footer/>
    </>
  );
}

export default App;
