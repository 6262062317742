import React from 'react';
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

export default function DecSchedule() {
    const navigate  = useNavigate();
    const classes = [
        {
            image: "images/festiveoutside.jpg",
            date: "DECEMBER 1",
            title: "Festive Favorites: Christmas and New Year Baking Workshop",
            href:'/festivefavorites'
            
        },
        {
            image: "images/breadout.jpg",
            date: "DECEMBER 2",
            title: "Basics of Bread 1 Day Hands-On Workshop",
           href:'/basicsofbread'
        },
        {
            image: "images/cookies.jpg",
            date: "DECEMBER 3",
            title: "Cookies and Macaron 1 Day Hands-On Workshop",
            href:'/cookies&macaron'
        },
        {
            image: "images/muffinout.jpg",
            date: "DECEMBER 4",
            title: "Muffins 1 Day Hands-On Workshop",
            href:'/muffins'
        },
        {
            image: "images/travelcakeout.jpg",
            date: "DECEMBER 9",
            title: "Travel Cakes 1 Day Hands-On Workshop",
            href:'/travelcakes'
        },
        {
            image: "images/cupcakeout.jpg",
            date: "DECEMBER 10",
            title: "Cup Cakes 1 Day Hands-On Workshop",
            href:'/cupcakes'
        },
        {
            image: "images/sweettartout.jpg",
            date: "DECEMBER 11",
            title: "Tarts (Sweet and Savoury) 1 Day Hands-On Workshop",
            href:'/tarts'
        },
        {
            image: "images/dessertoutside.jpg",
            date: "DECEMBER 16, 17, 18",
            title: "Custards & Classic Desserts 3 Days Hands-On Workshop",
            href:'/custards and classic desserts'
        },
        {
            image: "images/puffpastryout.jpg",
            date: "DECEMBER 23, 24",
            title: "Puff Pastry 2 Days Hands-On Workshop",
            href:'/puffpastries'
        },
        {
            image: "images/viennooutside.jpg",
            date: "DECEMBER 30, 31",
            title: "Viennoiseries 2 Days Hands-On Workshop",
            href:'/viennoiseries'
        },
    ]
    const handleCardClick=(href) =>{
        navigate(href);
    }
    return (
        <>
            {/* <div className="aboutus_bg">
                <div className="aboutus-img-content">
                    <h1>November Schedule</h1>
                </div>
            </div> */}
            <div className='nov_class'>
            <div className="class-head" id="course">
                
                <div className="pop-class ">
                    <h3 style={{paddingTop:'3%',paddingBottom:'3%'}}>Whisk, Bake, and Create – December Schedule 2024 !</h3>
                    <Container>
                        <Row >
                            {classes.map((cls, index) => (

                                <Col md={3} key={index} style={{ marginBottom: '5%' }}>
                                    <Card className="cookie november-class" style={{ border: 'none' }} onClick={() => handleCardClick(cls.href)}>
                                        <Card.Body>
                                            <div className="image">
                                                <Card.Img src={cls.image} className="fixed-height-img" loading="lazy"/>
                                                <div className="cls-text">
                                                    <Card.Text>
                                                        <p className="cls-date">

                                                            <i className="fa-solid fa-calendar-days" style={{ color: "#e65c8c" }}></i>&nbsp;
                                                            {cls.date}
                                                        </p>
                                                    </Card.Text>
                                                    <Card.Title>
                                                        <h5>{cls.title}</h5>
                                                    </Card.Title>
                                                    <Card.Text>
                                                            <p className="cls-para">{cls.description}</p>
                                                           
                                                        </Card.Text>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                            }
                        </Row>
                    </Container>
                </div>
                
            </div> 
            </div>
        </>
    )
}