// eslint-disable-next-line jsx-a11y/anchor-is-valid
import React from 'react';
import { Card, Button, Accordion, Row, Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function Diploma() {
const navigate = useNavigate();

    const navigateToCourses = () =>{
        navigate('/contactus');
    }
    return (
        <>
            <div className="aboutus_bg">
                <div className="aboutus-img-content">
                    <h1>Diploma Courses</h1>
                </div>
            </div>
            <div className='diplomaPge'>
                <section>
                    <div className="header-section" style={{ fontFamily: 'DancingScript,cursive' }}>
                        <h1 style={{ fontSize: '28px' }}>Diploma in Bakery and Pastry Arts</h1>
                    </div>
                </section>

                <Row style={{ margin: '0', padding: '0' }}>
                    <Col md={8} style={{ paddingLeft: "5%", lineHeight: '2.5' }}>

                        {/* Header Section */}
                        <section className="about-course" >
                            <h2>About Course</h2>
                            <p style={{ textAlign: 'justify' }}>The Diploma in Bakery and Pastry Arts is a comprehensive
                                program designed to equip you with the advanced skills and techniques needed to excel in
                                the baking and pastry industry. This course covers every
                                aspect of bakery and pastry arts, from the fundamentals
                                to intricate designs and modern trends, ensuring you
                                are industry-ready.</p>
                        </section>

                        <section className="learn-section" style={{ fontFamily: 'Roboto' }}>
                            <h2>What Will You Learn?</h2>
                            <ul>
                                <li><strong>Core Baking Techniques:</strong> Master the essentials of bread-making, dough handling, and pastry production, ensuring you can create bakery-quality items with precision.</li>
                                <li> <strong>Pastry Artistry:</strong> Delve into the world of fine pastries, including croissants, eclairs, tarts, and puff pastries. Learn how to balance flavor, texture, and presentation.</li>
                                <li><strong>Cake Decoration & Design:</strong> Gain expertise in cake decorating, sugar crafting, and fondant work, turning cakes into works of art for special occasions and events.</li>
                                <li><strong>Chocolate & Confectionery:</strong> Develop specialized skills in creating chocolates, pralines, and other confectionery delights, exploring both classic and contemporary techniques.</li>
                                <li><strong>Kitchen Management & Hygiene:</strong> Learn the importance of kitchen safety, cleanliness, and time management, key to running a successful bakery or patisserie.</li>
                                <li><strong>Menu Planning & Costing:</strong> Understand how to develop a creative yet cost-effective menu, balancing artistic expression with business profitability.</li>
                            </ul>
                        </section>

                        <section style={{ fontFamily: 'Roboto' }}>
                            <h2>Who Should Enroll?</h2>
                            <p>This diploma is ideal for individuals who want to pursue a professional career in the bakery and pastry arts. Whether you are a beginner with a passion for baking or a culinary professional seeking to expand your expertise, this course offers the perfect balance of theory and hands-on practice.
                            </p>
                        </section>
                        {/* What Will You Learn Section */}
                        <section className="learn-section">
                            <h2>Course Features:</h2>
                            <ul>
                                <li>FTaught by experienced pastry chefs and industry professionals.</li>
                                <li>State-of-the-art facilities with professional-grade equipment.</li>
                                <li>Opportunities for internships and real-world experience.</li>
                                <li>Small class sizes for personalized learning.</li>
                                <li>Certification recognized by the culinary industry.</li>
                            </ul>
                        </section>

                        <section className="about-course" >
                            <h2>Affiliation and Accreditation</h2>
                            <p style={{ textAlign: 'justify' }}>Our Diploma program is affiliated with the Council for Vocational Education and Development (CVESD), a government-approved organization. Upon successful completion, students will receive:</p>
                            <ul>
                                <li>A certificate from Finesse Academy for Bakery and Pastry Arts.</li>
                                <li>A certificate from CVESD (Counsil for Vocational Education and Skill Development).</li>
                            </ul>
                        </section>

                        <section className="learn-section">
                            <h2>Certification</h2>
                            <ul>
                                <li>This dual certification ensures our students meet industry standards and are well-prepared for a successful career in bakery and pastry arts.</li>
                            </ul>
                        </section>


                        {/* Course Curriculum Section */}
                        <section className="curriculum-section">
                            <h2>Course Curriculum</h2>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Theory</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Hygiene & Food Safety, Pastry Fundamentals.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Breads</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>International & Artisan Breads Enriched Breads & Viennoiseries.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Quick Breads</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Cupcakes & Muffins Tea Cakes, Sponges & Cookies.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>French Classical Desserts & Pastries</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Pies & Tarts Custards, Creams & Sauces, Eclairs & Choux Meringues & Macarons.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Daily and Weekly Revision (Muraja'ah)</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Regular review sessions of previously memorized portions. Structured revision system to maintain long-term retention.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Modern French Desserts</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Entremets, Petit Gateau & Verrines Fine Dining Style Plated Desserts.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>Chocolate & Confections</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Basics Of Chocolate, Petit Four & Bonbons Truffles & Other Confections.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>Cakes</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Basic Cakes & Icing Wedding Cake & Fondant Design Tier Cakes.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>Business Set Up</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Product Costing & Budgeting Social Media Marketing.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </section>
                    </Col>
                    <Col md={4}>
                        {/* Sidebar Section */}
                        <div className="course-sidebar">
                            <Card className="sidebar-card">
                                <Card.Body>
                                    {/* YouTube Video */}
                                    <div className="video-container">
                                        <img src='images/choco.webp' style={{ width: '100%' }} />
                                    </div>

                                    {/* Pricing */}
                                    <p className="course-price">₹ 1,25,000<br/>
                                    <span className='course-amt'>  (Inclusive of GST)</span></p>

                                    <button onClick={navigateToCourses} className="add-to-cart">Enrol Now</button>
                                    {/* <Button variant="outline-primary" className="buy-course" block>Buy Course</Button> */}

                                    {/* <hr className="course-divider" /> */}
                                    &nbsp;
                                    {/* Course Details */}
                                    <ul className="course-includes">
                                        {/* <li><strong>Lectures:</strong> 12</li> */}
                                        <li><strong>Duration:</strong> 4 months</li>
                                        <li><strong>Schedule: </strong> 3 days a week</li>
                                        <li><strong>Timing: </strong> 10.00 am-5.00 pm</li>
                                        {/* <li><strong>Deadline:</strong> November 29, 2024</li> */}
                                        <li><strong>Certificate:</strong> Yes</li>
                                    </ul>
                                    {/* <Button  className="share-course">Share this course</Button> */}
                                </Card.Body>

                            </Card>
                        </div>
                    </Col>
                </Row>
                {/* About Course Section */}
            </div>

        </>
    )
}