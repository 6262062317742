import React, { useState } from "react";
import { Col, Row, Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Custard() {
    const [quantity, setQuantity] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date("2024-12-16,"));
    const courseDetails = {
        name: "Custards and Classic Desserts - 3 Days Hands-On Workshop",
        // price: "Rs. 4000.00",
        date: "16, 17, 18 December 2024",
        timing: "10:00 am to 5:00 pm",
        ageCriteria: "13 years & Above",
        whatsappNumber: "9445744663"
    }
    const handleBookNow = () => {
        const dateText = selectedDate ? selectedDate.toLocaleDateString() : "No date selected";
        const message = `Course Name: ${courseDetails.name}%0ADate: ${courseDetails.date}%0ATiming: ${courseDetails.timing}%0AQuantity: ${quantity}`;
        const whatsappUrl = `https://wa.me/${courseDetails.whatsappNumber}?text=${message}`;
        window.open(whatsappUrl, "_blank");
    };
    return (
        <>
            {/* <div className="aboutus_bg">
                <div className="aboutus-img-content">
                    <h1>Cheesecake</h1>
                </div>
            </div> */}
            <div className="cheesecake_nov">
                <div className="nov-schedule">
                    <Row style={{ margin: '0' }}>
                        <Col md={7}>
                            <div className="nov-cheesecake-img">
                                <img src="/images/dessertinside.jpg" alt="december_custard_schedule" width={'100%'} loading="lazy"/>
                            </div>
                        </Col>

                        <Col md={5} className="sticky-img">
                            <div className="nov-cheesecake-details">
                                <h1>{courseDetails.name}</h1>
                                <h6>{courseDetails.price}</h6>

                                <p><strong>Date</strong></p>
                                <p className="cls-date">

                                    <i className="fa-solid fa-calendar-days" style={{ color: "#000" }}></i>&nbsp;
                                    {courseDetails.date}
                                </p>
                                {/* <DatePicker
                                    selected={selectedDate}
                                    // onChange={(date) => setSelectedDate(date)}
                                    dateFormat="dd MMMM yyyy"
                                    // placeholderText="Select a date"
                                    className="form-control mb-3 datepicks"
                                    disabled
                                /> */}

                                <p><strong>Timing:</strong></p>
                                <p className="timing"> {courseDetails.timing}</p>
                                <p><strong>Age Criteria:</strong> </p>
                                <p className="age">{courseDetails.ageCriteria}</p>

                                <Form.Group>
                                    <Form.Label><strong>Quantity</strong></Form.Label>
                                    <div className="quantity-controls">
                                        <button onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)} className="quantity-btn">
                                            −
                                        </button>
                                        <span className="quantity-display">{quantity}</span>
                                        <button onClick={() => setQuantity(quantity + 1)} className="quantity-btn">
                                            +
                                        </button>
                                    </div>
                                </Form.Group>

                                <Button variant="dark" onClick={handleBookNow} className="book-now">
                                    Book Now
                                </Button>
                                <div className="course-learn-section">
                                    <h6>Course Type</h6>
                                    <p>Beginners, Specialized Workshop.</p>
                                    <h6>About the Course</h6>
                                    <p>Experience the world of timeless sweetness with our Custards & Classic Desserts Workshop. Perfect for dessert lovers, this hands-on session celebrates the art of creating smooth, velvety custards and iconic confections that never go out of style.<br />
                                        From rich, creamy delights to beautifully balanced flavors, this workshop is your ticket to mastering desserts that are both elegant and indulgent. Elevate your baking repertoire with creations that are sure to impress every time. Join us for a day of culinary artistry and create desserts that delight the senses! </p>
                                    <h6>What you will Learn</h6>
                                    {/* <p>
                                        <strong>Menu</strong>
                            
                                    </p> */}

                                    <ul>
                                        <li>Tiramisu</li>
                                        <li>Baklava</li>
                                        <li>Choco Lava Cake</li>
                                        <li>New York Cheese Cake</li>
                                        <li>Classic Opera</li>
                                        <li>Yule Log</li>
                                        <li>Charlotte</li>
                                        <li>Creme Caramel</li>
                                        <li>Creme Brulee</li>
                                        <li>Bread and Butter pudding</li>
                                        <li>Clafoutis</li>
                                        <li>Pot de creme</li>

                                    </ul>

                                    <h6>What to Expect</h6>
                                    <p>A fully hands-on, practical learning experience in a well-equipped training space. Enjoy personalized, one-on-one attention, and gain insider tips and tricks for the perfect learning journey. Learn from experts dedicated to helping you master every detail. Walk away with skills and confidence to take on your baking future.</p>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}